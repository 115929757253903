
import Vue from "vue";
import { Route } from "vue-router";

import http from "@/http";
import { Activity, Breadcrumb, Asset } from "@/interfaces";

export interface DataType {
  asset?: Asset;
}

export default Vue.extend({
  data(): DataType {
    return {
      asset: undefined
    };
  },
  computed: {
    breadcrumbs(): Breadcrumb[] {
      if (this.asset == null || this.asset.owner == null) {
        return [];
      }

      return [
        {
          text: "ホーム",
          disabled: false,
          to: "/"
        },
        {
          text: "オーナー",
          disabled: false,
          to: "/owners"
        },
        {
          text: `# ${this.asset.owner.id} ${this.asset.owner.name}`,
          disabled: false,
          to: `/owner/${this.asset.owner.id}`
        },
        {
          text: `他社管理物件`,
          disabled: true
        }
      ];
    }
  },
  async beforeRouteEnter(to: Route, from: Route, next: any) {
    const url = `/assets/${to.params.id}`;

    const { data } = await http.get<Asset>(url);

    next((vm: Vue & DataType) => {
      vm.asset = data;
    });
  }
});
